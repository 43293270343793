import { getFeatureFlag } from '@pulselive/launchdarkly-sdk-wrapper';

const ROUTES = {
    HOME: {
        type: 'route',
        path: '/',
        routes: [
            {
                type: 'application',
                name: '@pulselive/homepage'
            }
        ],
        props: {
            text: 'Home'
        }
    },
    PREFERENCES: {
        type: 'route',
        path: '/preferences',
        routes: [
            {
                type: 'application',
                name: '@pulselive/pulselive-id-preferences-microfrontend'
            }
        ],
        props: {
            text: 'Preferences'
        }
    },
    METRICS: {
        type: 'route',
        path: '/metrics',
        routes: [
            {
                type: 'application',
                name: '@pulselive/pulselive-id-metrics-microfrontend'
            }
        ],
        props: {
            text: 'Metrics'
        }
    }
};

export const getRoutes = () => {
    const routes = [ROUTES.HOME, ROUTES.PREFERENCES];

    if (getFeatureFlag('metrics-dashboard')) {
        routes.push(ROUTES.METRICS);
    }

    return routes;
};

export const navItemsData = (customRoutes) =>
    customRoutes.map((route) => {
        return {
            path: route.path,
            text: route.props.text,
            createBtn: { ...route.props.createBtn }
        };
    });
